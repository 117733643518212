<template>
    <v-card flat v-if="product && product.id > 0" @click.stop="onClick">
      <v-container class="fill-height" fluid>
        <v-row dense>
          <v-col cols="12">
            <v-card flat
              height="100%"
              class="flexcard"
            >
              <v-card-text class="text-center pa-0">
                <image-viewer
                  :images="imageLibrary.images"
                  :noLibrary="true"
                  :emit="true"
                  @click="onClick"
                ></image-viewer>
                <div class="text-subtitle-2 font-weight-bold">{{ product.title }}</div>
                <div v-if="false" class="subheading trimmed" style="min-height: 22px;">{{descriptionText}}</div>
                <div class="text-subtitle-2" v-if="product.comingSoon">Coming Soon</div>
                <div class="text-subtitle-2" v-else-if="product.hasRange">{{product.priceRange[0] | usDollars}} - {{product.priceRange[1] | usDollars}}</div>
                <div class="text-subtitle-2" v-else>{{product.priceRange[0] | usDollars}}</div>
              </v-card-text>
              <v-btn v-if="false" color="success" @click="onDebug">debug</v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['product'],
  data () {
    return {
      loading: false,
      manual: false,
      validPlayer: false,
      searchR: null,
      cartDialog: false,
      selected: [],
      quantity: 1,
      shippingInfo: {
        delivery: 'none',
        name: null,
        phone: null,
        email: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null
      },
      validShipping: false
    }
  },
  computed: {
    ...mapGetters(['color1IsDark', 'color2IsDark']),
    imageLibrary () {
      return this.product.jsonProps && this.product.jsonProps.imageLibrary
    },
    player () {
      return this.product.player
    },
    description () {
      return this.product.description
    },
    descriptionText () {
      var span = document.createElement('span')
      span.innerHTML = this.description
      var children = span.querySelectorAll('*')
      for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) {
          children[i].textContent += ' '
        } else {
          children[i].innerText += ' '
        }
      }
      return [span.textContent || span.innerText].toString().replace(/ +/g, ' ')
    }
  },
  methods: {
    onDebug () {
      console.log(this.product)
    },
    onClick () {
      this.$router.push({ name: 'product-details', params: { tag: this.product.urlTag } })
    }
  },
  components: {
    ImageViewer: () => import('@/components/Utils/ImageLibraryViewer.vue')
  }
}
// $emit('selected', product)
</script>

<style scoped>
.outlined-card {
  border: 2px solid var(--v-color3-base) !important
}
.trimmed {
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}
a {
  text-decoration: none;
}
</style>
