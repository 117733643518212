<template>
  <v-container fluid :class="{'pa-0': sm}">
    <v-row dense>
      <v-col cols="12" class="pa-0">
        <v-card>
          <v-toolbar color="color1 color1Text--text" class="mx-auto" :dark="color1IsDark">
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              :slot="sm ? 'extension' : null"
              color="color3"
              append-icon="fas fa-search"
              label="Search"
              hide-details
              v-model="search"
              solo-inverted
              clearable
              :class="{'mb-3': sm}"
            ></v-text-field>
            <v-btn color="color1Text" text icon @click.stop="userFilter = !userFilter" v-if="false">
              <v-icon>fas fa-filter</v-icon>
            </v-btn>
          </v-toolbar>
          <loading-bar :value="loading"></loading-bar>
          <v-data-iterator
            :items="productObj"
            :search="search"
            item-key="id"
            :items-per-page="-1"
            hide-default-footer
          >
            <template v-slot:default="{ items }">
              <v-row dense>
                <v-col cols="12" sm="6" md="3" v-for="p in items" :key="p.id">
                  <store-front-product-card
                    :product="p"
                  ></store-front-product-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
      <v-col class="text-right" cols="12" >
        <v-btn color="success" v-if="user && user.vbl" @click.stop="onDebug">debug</v-btn>
        <v-btn color="color3" text icon @click.stop="getList" x-small>
          <v-icon x-small>fas fa-sync-alt</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Product from '@/classes/Product'
import StoreFrontProductCard from './StoreFrontProductCard'
import * as mutations from '@/store/MutationTypes'

export default {
  props: ['store'],
  data () {
    return {
      loading: false,
      search: null
    }
  },
  computed: {
    ...mapGetters(['user', 'subdomain', 'color1IsDark', 'organizationName']),
    products () {
      return this.store.products
    },
    xs () {
      return this.$vuetify.breakpoint.xsOnly
    },
    sm () {
      return this.$vuetify.breakpoint.smAndDown
    },
    title () {
      return this.organizationName ? `${this.organizationName} Store` : 'Store'
    },
    productObj () {
      return this.products && this.products.map(m => new Product(m))
    },
    organization () {
      return this.getPageInfo(this.username)
    },
    groupedProducts () {
      const groups = []
      this.products.forEach(p => {
        let g = groups.find(f => f.id === p.organization.id)
        if (!g) {
          g = {
            id: p.organization.id,
            name: p.organization.name,
            products: []
          }
          groups.push(g)
        }
        g.products.push(p)
      })

      return groups
    },
    useGroups () {
      return true
    }
  },
  methods: {
    // asyncData () {
    //   this.loading = true
    //   this.$VBL.store.products.getAll(this.subdomain)
    //     .then(r => {
    //       this.products = r.data
    //     })
    //     .catch(e => console.log(e))
    //     .finally(() => { this.loading = false })
    // },
    onDebug () {
      console.log(this.groupedProducts)
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        this.asyncData()
      }
    },
    loading: function (v) {
      if (v) {
        this.$store.commit(mutations.LOADING, 'store-front')
      } else {
        this.$store.commit(mutations.LOADING_COMPLETE, 'store-front')
      }
    }
  },
  components: {
    StoreFrontProductCard
  },
  mounted () {
    // this.asyncData()
  }
}
</script>

<style>

</style>
